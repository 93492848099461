import { Form, FormikHelpers } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useState } from "react";
import * as Yup from "yup";

import Button from "../../../../../../atoms/button/Button";
import RadioButton, {
  RadioButtonVariantsName,
} from "../../../../../../core/ui/atoms/radio-button/RadioButton";
import RadioGroup from "../../../../../../core/ui/molecules/radio-group/RadioGroup";
import { Image } from "../../../../../../core/utils/images";
import PageStepForm, {
  PageStepFormDataElement,
} from "../../../../../../organisms/page-step-form/PageStepForm";
import { Events, track } from "../../../../../../utils/analytics";
import { useHowKnowUs } from "../../../../application/how-know-us-use-cases";
import * as styles from "./HowKnowUs.module.scss";

interface HowKnowUsFormValues {
  howKnowUs: string;
}

const magnifier = require("../../../images/magnifier.svg") as Image;

const magnifierSrc: string = magnifier.default;

const HowKnowUs = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const [howKnowUs, setHowKnowUs] = useHowKnowUs();
  const [validateOnMount, setValidateOnMount] = useState(true);

  const nextUrl = `/${i18n.language}/onboarding/email/`;

  const HowKnowUsFormData: PageStepFormDataElement = {
    initialValues: {
      howKnowUs,
    },
    validateOnMount,
    validationSchema: Yup.object({
      howKnowUs: Yup.string().required(t("common.validation.required")),
    }),
    handleSubmit: (
      values: HowKnowUsFormValues,
      { setSubmitting }: FormikHelpers<HowKnowUsFormValues>
    ): void => {
      setSubmitting(true);
      setHowKnowUs(values.howKnowUs);
      track(Events.FORM_ANSWERED, {
        question: "How Know Us",
        answer: values.howKnowUs,
      });
      setTimeout(() => {
        void navigate(nextUrl);
      }, 500);
    },
    children: (props): ReactElement => {
      const { isSubmitting, isValid, submitCount, setFieldValue } = props;

      const handleClick = () => {
        setValidateOnMount(submitCount !== 0);
      };
      const handleChange = (value: string): void => {
        setFieldValue("howKnowUs", value);
      };

      return (
        <Form className={styles.howKnowUsForm}>
          <RadioGroup
            aria-label={t("onboarding.how_know_us_radio_group.aria_label")}
            onChange={(value) => handleChange(value)}
            adoptionClassName={styles.radioGroup}
            {...(howKnowUs.length && { defaultValue: howKnowUs })}
          >
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <RadioButton
                key={item}
                value={t(`onboarding.how_know_us.answer_${item}`)}
                variantName={RadioButtonVariantsName.hasIcon}
                adoptionClassName={styles.radioButton}
              >
                {t(`onboarding.how_know_us.answer_${item}`)}
              </RadioButton>
            ))}
          </RadioGroup>
          <Button
            type="submit"
            disabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            onClick={handleClick}
          >
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("onboarding.how_know_us.title")}
      image={magnifierSrc}
      formData={HowKnowUsFormData}
    />
  );
};

export default HowKnowUs;
